import EditorAnnotator from '@cdo/apps/EditorAnnotator';
import {ai_rubric_cyan} from '@cdo/apps/util/color';

import tipIcon from './images/AiBot_Icon.svg';
import infoIcon from './images/info-icon.svg';

/**
 * Annotation tooltip styling.
 *
 * Needs to be here to capture the image URL and because EditorAnnotator can
 * better add the styling overrides directly to the element so they always
 * apply over other styling (including those also directly added to the
 * element by the editor implementation, aka Droplet or Ace).
 */
const tipStyle = {
  backgroundImage: `url(${tipIcon})`,
  backgroundColor: '#333',
  backgroundPosition: '2px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
  borderColor: '#555',
  color: 'white',
  borderRadius: '6px',
  padding: '6px',
  paddingLeft: '30px',
};

/**
 * Adds annotations to the source code viewed in the current editor based
 * on the AI evidence of the learning goal referenced by the given index.
 *
 * Observations is a text block generated by the AI. This will parse out
 * sections that match a particular style:
 *
 *   Line x: Some description `some code`
 *   Line x-y: Some description `some code`
 *
 * More than one can appear within the same text chunk:
 *
 *   Line 3: Creates a sprite `var p = createSprite(100, 100);` Line 4: Sets
 *   velocity `p.velocityX = 0;`
 *
 * In this case, we expect to highlight lines 3 and 4 and to annotate each with
 * the provided description. Although, we might find that this code actually
 * exists on lines 5 and 6, since the AI is somewhat unreliable about line
 * number reporting. The `findCodeRegion` function is responsible for finding
 * the actual lines.
 *
 * When the AI gives us multiple lines, it will generally remove the whitespace.
 * This means that the newlines are not within the observation text block. We
 * have to match the code similarly. You might see this:
 *
 *   Line 5: Jump condition `if (keyDown('up')) { p.velocityY = -100; }`
 *
 * In this case, we might find this code on lines 8 through 10. So, we would
 * annotate line 8 and highlight lines 8 through 10.
 *
 * Another case requiring a fallback is when the evidence feedback does not
 * contain a message. Something like: `Lines 5-6: `if (something) {...}`.
 * In this case, we can highlight the line, but we want to annotate the line
 * with the full observations. This is why we also pass those along.
 *
 * Also seen above is the way the AI might truncate code. In that case, we
 * fallback to finding the first partial code match using the code before the
 * ellipsis (...).
 *
 * This will return a list of annotation blocks containing the line numbers
 * and the description that should be listed out to the viewer. Only items
 * returned here will be listed. However, other content may be highlighted
 * that does not end up in that list.
 *
 * This table suggests the fallbacks we have in place for responding to the
 * provided 'evidence' column. Ideally, the evidence column can be parsed into
 * items with a line number, code snippet, and a message (this first row of
 * this table.) Then, it may be missing any one of those items and has to be
 * gracefully handled. We should still strive to fix upstream the prompts such
 * that they provide the ideal form.
 *
 * line number? | has code? | message? | annotated by | line number via
 * ---------------------------------------------------------------------
 * yes          | yes       | yes      | evidence     | code snippet
 * yes          | no        | yes      | evidence     | AI line number
 * yes          | yes       | no       | observations | code snippet
 * yes          | no        | no       | observations | AI line number
 * no           | --        | --       | none         | none
 *
 * @param {string} evidence - A text block described above.
 * @param {string} observations - The text block for the overall observations, if needed.
 * @param {function} hoverCallback - A function to call when the tooltip is opened.
 * @returns {Array} The ordered list of annotations.
 */
export const annotateLines = (evidence, observations, hoverCallback) => {
  let ret = [];

  // When we fail to find specific instances of evidence, we use the
  // 'observations' column to fill out the evidence section. This fall back
  // is not our ideal since it does not include line numbers or landmarks in
  // the code to follow.
  let shouldIncludeObservationsColumn = false;

  // Go through the AI evidence
  // For every reference the AI gave us, we will find it in the code.
  // The AI has trouble giving line numbers, so even though we parse
  // those out, we do not trust them and instead find the code it
  // references to highlight it.
  for (const match of evidence.matchAll(
    'Lines? (\\d+)(?:\\s*-\\s*(\\d+))?:(.+?)\\s*(?=Line|$)'
  )) {
    let lineNumber = parseInt(match[1]);
    let lastLineNumber = parseInt(match[2] || lineNumber);
    let found = false;
    const context = ' ' + match[3].trim();

    // The message is everything before the code reference (if it exists)
    let message = (
      context.includes('`')
        ? context.substring(0, context.indexOf('`'))
        : context
    ).trim();

    // We look at all of the code references the AI gave us which are
    // surrounded by backticks.
    const references = context.substring(message.length).trim();

    // If message is blank, just put in the observations
    if (message === '') {
      message = observations;
    }

    for (const submatch of references.matchAll(/`([^\`]+)`/g)) {
      let snippet = submatch[1].trim();

      // Skip empty code snippets... just in case the AI very strangely
      // gives an empty code response (e.g. 'Line 1: Description ` `')
      if (snippet === '') {
        continue;
      }

      // Find where this snippet actually happens
      let position = EditorAnnotator.findCodeRegion(snippet, {
        stripComments: true,
      });

      // If it could not find the region, try a less aggressive approach
      // by looking for common patterns.
      if (!(position.firstLine || position.lastLine)) {
        // We often see the AI truncate code blocks via something like:
        // 'if (something) { ... }' ... This will at least find the beginning of it.
        const truncationRegex = /{\s*[.]+\s*}/;
        if (snippet.match(truncationRegex)) {
          const prologue = snippet.split(truncationRegex)[0].trim();
          position = EditorAnnotator.findCodeRegion(prologue, {
            stripComments: true,
          });
        }
      }

      // Annotate that first line and highlight all lines, if they were found
      if (position.firstLine && position.lastLine) {
        found = true;
        EditorAnnotator.annotateLine(
          position.firstLine,
          message,
          'INFO',
          ai_rubric_cyan,
          infoIcon,
          tipStyle,
          hoverCallback
        );
        for (let i = position.firstLine; i <= position.lastLine; i++) {
          EditorAnnotator.highlightLine(i, ai_rubric_cyan);
        }

        if (message === observations) {
          shouldIncludeObservationsColumn = true;
        } else {
          ret.push({
            firstLine: position.firstLine,
            lastLine: position.lastLine,
            message: message,
          });
        }
      }
    }

    // If we have some evidence but couldn't find it, use the AI/Agent provided line
    // numbers, which may be inaccurate.
    if (!found) {
      EditorAnnotator.annotateLine(
        lineNumber,
        message,
        'INFO',
        ai_rubric_cyan,
        infoIcon,
        tipStyle,
        hoverCallback
      );
      for (let i = lineNumber; i <= lastLineNumber; i++) {
        EditorAnnotator.highlightLine(i, ai_rubric_cyan);
      }

      // If we are forcing these lines to have the bulk annotation of
      // the observations column, we do not append it to the list. This way,
      // it does not get listed out.
      if (message === observations) {
        shouldIncludeObservationsColumn = true;
      } else {
        ret.push({
          firstLine: lineNumber,
          lastLine: lastLineNumber,
          message: message,
        });
      }
    }
  }

  // If there was no evidence given, we do at least want to include the
  // observations column.
  if (ret.length === 0) {
    shouldIncludeObservationsColumn = true;
  }

  // Somewhere, we annotated with the obserations column, or we have no other
  // sources of evidence. We want to list out the observations column in our
  // rendered list. So, here we parse out the observations column.
  if (shouldIncludeObservationsColumn) {
    observations.split('. ').forEach(observation => {
      ret.push({
        message: observation,
      });
    });
  }

  return ret;
};

/**
 * Clear prior line annotations
 */
export const clearAnnotations = () => {
  EditorAnnotator.clearAnnotations();
  EditorAnnotator.clearHighlightedLines();
};
